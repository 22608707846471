<template>
  <div class="boxs">
    <div v-for="(item, index) in items"  class="box-container" :key="index">
        <div class="box">
          <div class="box-front" :style="{backgroundImage: 'url(' + item.image.src + ')'}">
            <div class="box-front-title">{{ item.title }}</div>
          </div>
          <div class="box-back">
            <div class="box-back-title">{{ item.title }}</div>
            <div class="box-back-description content" v-html="item.description"></div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Sceen",
  data: () => ({
    active: 0
  }),
  computed: {
    items(){
      return this.$store.getters.images;
    },
    first(){
      return this.items[0];
    },
    others(){
      let items = [];
      for (let i = 1; i < this.items.length; i++) {
        items.push(this.items[i]);
      }
      return items
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.deer {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  shape-outside: polygon(100% 0%,98% 39%,84% 71%,64% 100%,0% 100%,0% 0%);
  clip-path: polygon(100% 0%,98% 39%,84% 71%,64% 100%,0% 100%,0% 0%);
}
.boxs{
  width: 100%;
  height: 100%;
  display: grid;
  min-height: 100%;
  grid-auto-flow: row;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

}

.box-shift{
  grid-area: span 1 / span 3 / span 1 / span 3;
  align-self: stretch;
  display: flex;

}
.box-shift-image{
  flex: 5 10%;
}
.box-shift-text{
  flex: 5 10%;
  padding-left: 2rem;
}

.box-container{
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  grid-area: span 1 / span 1 / span 1 / span 1;
}
.box{
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -moz-transition: 0.6s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 11%);
}
.box-front,.box-back{
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -moz-transform: rotateY(0deg);
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -o-transform: rotateY(0deg);
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  -ms-transform: rotateY(0deg);
  transition: 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: flex-end; */
  /* padding: 0 4rem 2.5rem; */
  flex-direction: column;
}
.box-front{
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  background: lightgreen;
  z-index: 2;

  justify-content: end;
}
.box-back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);

  justify-content: start;
  padding-top: 2.25rem;
  background-color: #fff;
  padding: 2rem;
  overflow: auto;
}
.box-container:hover .box-front, .box-container.hover .box-front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.box-container:hover .box-back, .box-container.hover .box-back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.box-front-title{
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  padding: 2rem 0;
  background: #fff;
}
.box-back-title{
  margin: 0 0 5px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
}
.box-back-description{
  padding-top: 0.5rem;
  font-size: 1.35rem;
}
.box-back-description p{
  margin-bottom: 0.5rem;
}
</style>