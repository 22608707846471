import { createApp } from 'vue'
import { createStore } from 'vuex'
import axios from "axios";
import App from './App.vue'

import dataPage from './assets/page.json'


// Create a new store instance.
const store = createStore({
    state: {
        num: 0,
        rid: null,
        images: [],
        regions: [],
        request: null,
        cancelSource: null
    },
    getters: {
        regions(state){
            return state.regions
        },
        images(state){
            return state.images
        },
        num(state){
          return state.num
        },
        rid(state){
            return state.rid
        },
        cancelSource(state){
            return state.cancelSource
        }
    },
    mutations: {
        setNum (state, value) {
            state.num = value
        },
        setRid (state, value) {
            state.rid = value
        },
        setImages (state, values) {
            state.images = values
        },
        setRegions (state, values) {
            state.regions = values
        },
        setCancelSource (state, value) {
            state.cancelSource = value
        }
    },
    actions: {
        searchRegions (context, {s}) {
            context.commit('setRegions', [])
            if (context.getters.cancelSource) {
                context.getters.cancelSource.cancel()
            }
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            context.commit( 'setCancelSource',  source)
            axios.get('https://pharmland.by/api/region?s=' + s, {
                cancelToken: source.token
            })
                .then( (res) => {
                    return res.data
                })
                .then( (data) =>{
                    context.commit('setRegions', data)
                })

        },
        page(context){
            context.commit('setNum', dataPage.num)
            context.commit('setImages', dataPage.images)
        }
    }
})

const app = createApp(App)
store.dispatch('page');
app.use(store)
app.mount('#app')
