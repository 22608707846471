<template>
  <vue-scroll-snap :fullscreen="true">
    <section class="item is-front hero h100p is-fullheight">
        <sceen></sceen>
    </section>
<!--    <section class="item is-map hero is-fullheight">-->
<!--      <div class="hero-body is-top">-->
<!--        <map-search></map-search>-->
<!--      </div>-->
<!--    </section>-->
  </vue-scroll-snap>

  <svg viewBox="0 0 1 1">
    <defs>
      <clipPath clipPathUnits="objectBoundingBox" id="deermask">
        <path
            d="M 0.08508143,0.26648138 C 0.15168465,0.2525687 0.14229234,0.13432172 0.15606688,0.10813432 0.17964935,0.06333748 0.26033311,0.05925901 0.26458333,0 H 0 v 0.26458333 z"
            fill="#fff" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
//import MapSearch from "@/components/MapSearch";
import Sceen from "@/components/Sceen";
import VueScrollSnap from "vue-scroll-snap";

export default {
  name: 'App',
  components: {
    VueScrollSnap,
    Sceen,
    //MapSearch
  }
}
</script>

<style lang="sass">
// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass"
@import "../node_modules/bulma/sass/base/_all.sass"
@import "../node_modules/bulma/sass/elements/button.sass"
@import "../node_modules/bulma/sass/elements/container.sass"
//@import "../node_modules/bulma/sass/elements/title.sass"
@import "../node_modules/bulma/sass/form/_all.sass"
//@import "../node_modules/bulma/sass/components/navbar.sass"
@import "../node_modules/bulma/sass/layout/hero.sass"
//@import "../node_modules/bulma/sass/layout/section.sass"
@import "../node_modules/bulma/sass/elements/content.sass"

html
  height: 100%
  width: 100%
  font-size: 62.5%
body
  min-height: 100%
  width: 100%
.full-view
  width: 100%
  height: 100%
#app
  height: 100%
  width: 100%
.h100p
  height: 100%
.item
  min-height: 500px
  padding: 2rem
.scroll-snap-container
  min-height: 100%
  min-width: 100%
.hero.is-fullheight
  .hero-body.is-top
    align-items: start
.scroll-snap-container.fullscreen
  overflow: hidden
</style>
